.container {
    padding: 32px;
    display: flex;
    justify-content: center;
}

.signInBox {
    width: 100%;
    max-width: 600px;
    padding: 16px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.note {
    display: block;
    margin: 1em 0;
}

@media (max-width: 500px) {
    .container {
        padding: 16px;
    }
}