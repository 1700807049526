.container {
    max-width: 1200px;
    padding: 8px;
    display: flex;
    gap: 32px;
    background-color: #f0f0f0;
}

.container section {
    flex-grow: 1;
}

.boardBox {
    display: inline-block;
    padding: 16px;
    border-radius: 2px;
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    width: 100%;
    max-width: 450px;
}

.divider {
    display: none;
}

@media (max-width: 900px) {
    .container {
        flex-direction: column;
    }

    .divider {
        display: block;
    }
}