.playerBoard {
    width: 100%;
    max-width: 450px;
}

.gameInfo {
    margin-top: 1em;
    padding: 8px;
    background-color: white;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}