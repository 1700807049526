.container {
    padding: 16px;
}

.legend {
    margin-top: 16px;
    display: flex;
    justify-content: space-evenly;
}

.error {
    background-color: hsl(0 73% 47% / 0.5);
    width: 50px;
    height: 50px;
}

.expected {
    background-color: hsl(206 73% 47% / 0.5);
}

.legendMarker {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-right: 8px;
    border: solid 2px black;
}

.segment {
    display: flex;
    align-items: center;
}