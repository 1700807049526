.boardTable {
    width: 100%;
    border: 3px solid #444;
    border-spacing: 0;
    border-collapse: collapse;
    background-color: white;
}

.boardTable td, .boardTable th {
    padding: 16px;
    font-size: 1.25rem;
    border: solid 2px #444;
    text-align: center;
}

.boardTable th {
    font-weight: 700;
    font-size: 1.5rem;
    border-bottom-width: 3px;
}

.boardTable.rowWise th {
    border-bottom-width: unset;
    border-right-width: 3px;
}

.selectable tbody td:not(.selected):hover {
    background-color: rgba(0,0,0,0.10);
}

.selectable tbody td {
    cursor: pointer;
}

.selected {
    background-color: hsl(206 73% 47% / 0.5);
}

.boardContainer {
    width: 100%;
}

.incorrect {
    background-color: hsl(0 73% 47% / 0.5);
}