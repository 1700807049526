.container {
    padding: 8px;
    max-width: 1200px;
}

.boardContainer {
    margin-top: 1em;
    padding: 16px;
    background-color: white;
    display: inline-block;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    max-width: 100%;
    overflow-x: auto;
    width: 100%;
}

.controls {
    display: flex;
    gap: 32px;
    align-items: center;
    margin-bottom: 8px;
}

.controls label {
    display: flex;
}

.controls label > * {
    margin-left: 8px;
}

.bingoBoard {
    margin-bottom: 1em;
}

