.topNav {
    height: 64px;
    padding: 8px 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    background-color: #2185d0;
    color: white;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    overflow-x: auto;
}

.topNav header {
    font-size: 1.5rem;
    font-family: monospace;
}

.topNav ul, .topNav li {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.topNav ul {
    display: flex;
    margin-left: 16px;
}

.topNav a {
    color: white;
    padding: 8px;
    border-radius: 4px;
    display: inline-block;
    font-size: 1.15rem;
}

.topNav a:hover {
    background-color: rgba(255,255,255,0.11);
}

.nameForm {
    margin-left: auto;
}