.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    padding-top: 32px;
    min-height: 100vh;
    background-color: #f0f0f0;
}

.header {
    margin-top: 8px !important;
}

.card {
    width: 100% !important;
    max-width: 600px !important;
}

@media (max-width: 500px) {
    .container {
        padding: 8px;
    }
}